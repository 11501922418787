<template>
    <div class="card card-custom bgi-no-repeat card-stretch mb-5"
        style="background-position: right top; background-size: 40% auto; background-image: url('/img/logo_half2.png')">
        <div class="card-header flex-wrap border-0 pt-6 pb-0">
            <div class="card-title mb-3">
                <h3 class="card-label">
                    <span :style="(textColor) ?'color:'+textColor:''">{{title}}</span> 
                    <span class="d-block text-primary pt-2 font-size-sm" style="line-height:20px;" v-html="subTitle"></span></h3>
            </div>
            <div class="card-toolbar">
                <slot name="toolbar"></slot>
            </div>
        </div>
        <div class="card-body">
            <slot name="content"></slot>
        </div>
    </div>
</template>
<script>
    export default {
        props: ['title', 'subTitle','textColor'],
    }
</script>